.allocation-create--cancel-button {
  transition: background-color 250ms linear;
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-black-2);
  border: 1px solid var(--color-black-2);
}

.allocation-create--cancel-button:hover {
  background-color: var(--color-neutral-5);
  color: var(--color-black-2);
}

.allocation-create--submit-button.disabled {
  background-color: var(--color-neutral-8);
  color: var(--color-neutral-11);
}

.allocation-create--submit-button.disabled:hover {
  background-color: var(--color-neutral-8);
  color: var(--color-neutral-11);
}

.allocation-create--submit-button {
  transition: background-color 250ms linear;
  background-color: var(--color-black-2);
  color: white;
}

.allocation-create--submit-button:hover {
  background-color: var(--color-black-1);
}

.allocation-create--program-row {
  cursor: pointer;
  transition: background-color 250ms linear;
}

.allocation-create--program-row:hover {
  background-color: var(--color-neutral-4);
}