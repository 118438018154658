.programs-page--container {
  padding-top: 60px;
  padding-bottom: 32px;
}

.programs-page--title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 60px 32px 60px;
}

.programs-page--scroll-area {
  height: calc(100vh - 156px);
  padding: 0px 60px 32px 60px;
  overflow: auto;
}

.programs-page--create-button {
  height: 32px;
  color: var(--color-black-2);
  border: 1px solid var(--color-black-2);
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease;
}

.programs-page--create-button:hover {
  background-color: var(--color-grey-3);
  color: var(--color-black-2);
}

.programs-page--card-container {
  display: flex;
  align-items: center;
  gap: 60px;
}

.programs-page--card-info-container {
  display: flex;
  max-width: 260px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.programs-page--program-status-container {
  border-radius: 4px;
}

.programs-page--program-status {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.programs-page--program-card-name {
  color: var(--color-green-2);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.programs-page--card-stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}

.programs-page--card-topline-number {
  color: var(--color-blue-3);
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.programs-page--stat-subtext {
  color: var(--color-grey-4);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.programs-page--card-number-units {
  color: var(--color-grey-4);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}