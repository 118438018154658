.customers-page--title {
  margin-left: 60px;
  font-size: 24px;
  font-weight: 700;
}

.customers-page--scroll-area {
  overflow: auto;
  height: calc(100vh - 164px);
  margin-top: 32px;
  padding: 0px 60px;
}

.customers-page--container {
  padding: 60px 0px 32px 0px;
}

.customer-card--logo {
  display: block;
}