body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --amp-nav-bar-width: 220px;
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080,
      #00000040,
      #00000030,
      #00000020,
      #00000010,
      #00000010,
      #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  --singularity-light-green-rgb: 178, 212, 213;
  --singularity-pale-green-rgb: 104, 172, 171;
  --singularity-green-rgb: 63, 148, 147;
  --singularity-dark-green-rgb: 38, 93, 93;

  /* TODO: should these be in the Mantine theme? */
  --color-neutral-1: #FFFFFF;
  --color-neutral-2: #FAFBFF;
  --color-neutral-3: #F9FAFC;
  --color-neutral-4: #F4F6FA;
  --color-neutral-5: #EDEFF5;
  --color-neutral-6: #E6E8F0;
  --color-neutral-7: #D8DAE5;
  --color-neutral-8: #C1C4D6;
  --color-neutral-9: #8F95B2;
  --color-neutral-10: #696F8C;
  --color-neutral-11: #474D66;
  --color-neutral-12: #101840;

  --color-grey-0: #F1F3F5;
  --color-grey-2: #CED4DA;
  --color-grey-3: #DEE2E6;
  --color-grey-4: #868686;

  --color-teal-0: #D3F9D8;
  --color-teal-1: #C3FAE8;
  --color-teal-2: #ECF8F5;
  --color-teal-6: #12B886;
  --color-teal-9: #087F5B;

  --color-green-0: #A8D9CE;
  --color-green-1: #3F9493;
  --color-green-2: #1A7371;
  --color-green-3: #62C1AB;

  --color-blue-0: #77A0C6;
  --color-blue-1: #4D7396;
  --color-blue-2: #54637A;
  --color-blue-3: #2A384D;
  --color-blue-5: #e9f0f6;
  --color-blue-6: #228BE6;

  --color-black-1: #202020;
  --color-black-2: #1A1B1E;
  --color-black-3: #141517;

  --color-red-0: #ED8192;
  --color-red-1: #FFE3E3;
  --color-red-2: #FFF5F5;
  --color-red-3: #C92A2A;
  --color-se-red-1: #ED264E;

  --color-orange-1: #fd7e14;
  --color-orange-2: #ffebda;

  --color-yellow-1: #e8bb52;
  --color-yellow-2: #f9f5eb;

  --color-indigo-0: #EDF2FF;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right,
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80,
        #ffffff40,
        #ffffff30,
        #ffffff20,
        #ffffff10,
        #ffffff10,
        #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.dashboard-global--notification-container {
  position: fixed;
  bottom: 24px;
  right: 40px;
  min-width: 120px;
}

.error-boundary-text--description {
  margin: 16px 0;
  font-size: 14px;
  color: var(--color-neutral-12);
}

.error-boundary-text--title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-neutral-11);
}

.error-boundary--container {
  display: flex;
  align-items: center;
  margin: 60px auto;
  width: 50vw;
  border: 1px solid var(--color-neutral-7);
  padding: 16px 24px;
  border-radius: 8px;
}

.error-boundary--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  width: 72px;
  height: 72px;
  background: var(--color-red-1);
  flex-shrink: 0;
  border-radius: 50%;
}

.error-boundary--breadcrumbs {
  padding-top: 16px;
  margin-left: 60px;
}