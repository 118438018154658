.projections-page--scroll-area {
  height: calc(100vh - 124px);
  padding: 32px 60px 32px 60px;
  overflow: auto;
}

.projections-page--title {
  white-space: nowrap;
  overflow: hidden;
  min-width: 140px;
}

.projections-page--selection-area {
  padding: 8px;
  background-color: var(--color-neutral-3);
  border-radius: 4px;
  border: 1px solid var(--color-grey-3);
}

.projections-page--filters-container {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.projections-page--selection-area .base-table--table-container {
  background-color: white;
}

.projections-page--name-table-cell {
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: none;
}

.projections-missing-forecast-create--help {
  font-size: 12px;
  margin-top: 8px;
  color: var(--color-neutral-10);
}