.account-details-page--scroll-container {
  overflow: auto;
  height: calc(100vh - 126px);
  padding: 32px 60px 24px 60px;
}

.account-details-page--contact-us-container {
  border-radius: 4px;
  background-color: var(--color-grey-0);
  padding: 32px;
}

.account-details-page--contact-us-button {
  border-radius: 4px;
  border: 1px solid var(--color-green-2);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-green-2);
  transition: color 0.2s ease;
}

.account-details-page--contact-us-button:hover {
  color: #4A8381;
  background-color: rgba(0, 0, 0, 0);
}