.program-subscription-page--title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.program-subscription-page--scroll-container {
  overflow: auto;
  height: calc(100vh - 100px); /* hard coded - gross */
  padding: 32px 60px;
}

.subscription-page-vs-forecast--container {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 2px;
}

.subscription-page-vs-forecast--explanation {
  font-size: 10px;
  margin-left: 4px;
  color: var(--color-grey-4);
}