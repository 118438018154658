.allocations-page--allocations-title {
  font-weight: 700;
  font-size: 24px;
}

.allocations-page--create-button {
  transition: background-color 250ms linear;
  height: 32px;
  color: black;
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0);
}

.allocations-page--create-button:hover {
  background-color: #e3e3e3;
  color: black;
}

.allocations-page--scroll-area {
  overflow: auto;
  height: calc(100vh - 124px);
  padding: 0px 60px 24px 60px;
}

.allocations-page--header-row {
  font-size: 14px;
  color: var(--color-blue-1);
  font-weight: 600;
}

.allocations-page--note-container {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.allocation-row--container {
  color: var(--color-blue-3);
}

.allocation-row--cell-container {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.allocation-row--cell-container:hover {
  cursor: pointer;
}

.allocation-row--context-menu-button:hover {
  cursor: pointer;
}

.allocation-row--toggle-container {
  cursor: pointer;
  padding: 8px;
}

.allocation-row--date-range {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.allocation-row-edit-note--container {
  display: flex;
  align-items: center;
}

.allocation-row--status-box {
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
}

.allocation-row--expanded-review-row {
  background-color: var(--color-neutral-4);
  border-top: 1px solid var(--color-grey-3);
  border-bottom: 1px solid var(--color-grey-3);
}

.allocations-page--release-report-modal-box {
  border-radius: 4px;
  background-color: var(--color-neutral-4);
  padding: 20px;
}

.report-release-history--value {
  font-size: 12px;
  color: var(--color-neutral-11);
}

.report-release-history--label {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-neutral-11);
  margin-left: 8px;
  margin-right: 4px;
}

.report-release-history-info--container {
  display: flex;
  align-items: center;
}

.report-release-history--status {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}