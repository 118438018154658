.program-outlet--pinned-area {
  padding: 32px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.program-outlet--title-container {
  display: flex;
  justify-content: space-between;
}

.program-outlet--container {
  position: fixed;
  width: calc(100% - var(--amp-nav-bar-width));
}

.program-outlet--context-menu {
  cursor: pointer;
  color: var(--color-black-2);
}

.program-outlet--context-menu:hover {
  color: var(--color-neutral-11);
}

.program-outlet--button {
  height: 32px;
  color: var(--color-black-2);
  border: 1px solid var(--color-black-2);
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease;
}

.program-outlet--button:hover {
  background-color: var(--color-grey-3);
  color: var(--color-black-2);
}