
.customer-load-cart--resolution-select input.mantine-Select-input{
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #dee2e6;
    border-radius: 0;
  }

  .customer-load-cart--topline-metrics-container {
    display: flex;
    margin-bottom: 20px;
  }


  .customer-load-cart--topline-metric-value.highlight {
    color: var(--color-blue-1);
  }

  .customer-load-cart--topline-metric-value {
    font-size: 32px;
    font-weight: 700;
    color: var(--color-black-1);
    width: fit-content;
  }

  .customer-load-cart--topline-metric-value-container {
    display: flex;
    align-items: baseline;
    margin-bottom: 4px;
  }

  .customer-load-cart--topline-metric-container {
    margin-right: 80px;
  }

  .customer-load-cart--topline-metric-explanation-label {
    font-size: 12px;
    flex-shrink: 0;
    color: var(--color-grey-4);
    margin-left: 4px;
  }

  .customer-load-cart--topline-metric-label {
    color: var(--color-grey-4);
    margin-left: 4px;
  }

  .customer-load-chart--vs-forecast-container {
    position: relative;
    bottom: 2px;
    display: flex;
    align-items: center;
  }

  .customer-load-chart--vs-forecast-explanation {
    font-size: 10px;
    margin-left: 4px;
    color: var(--color-grey-4);
    white-space: nowrap;
  }