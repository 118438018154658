

.emissions-reports-page--missing-container {
  padding: 15vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.emissions-reports-page-missing--icon {
  margin-right: 23px;
  padding: 20px;
  border-radius: 50%;
  height: 76px;
  width: 76px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-orange-2);
}

.emissions-reports-page-missing--title {
  font-weight: 600;
  font-size: 24px;
  color: var(--color-neutral-11);
}

.emissions-reports-page-missing--desc {
  margin: 16px 0;
  font-size: 14px;
}

.emissions-reports-page-missing--cta {
  display: flex;
  align-items: center;
}

.emissions-reports-page-missing--link {
  color: blue;
  cursor: pointer;
  margin-left: 24px;
  font-size: 10px;
}

.soco-emissions-report--subtitle {
  font-weight: 600;
}

.soco-emissions-report--title {
  font-weight: 400;
}

.soco-emissions-report--header {
  padding: 24px 40px;
  background-color: #127a00;
  color: white;
}

.soco-emissions-report--content {
  display: flex;
}

.soco-emissions-report--overview {
  width: 30%;
  flex-shrink: 0;
  font-size: 14px;
  padding: 24px 40px 24px 40px;
  background: #f7f8e4;
}

.soco-emissions-report-overview--title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  color: #508d33;
  background: #f7f8e4;
  margin: 0 auto;
  width: 34%;
  z-index: 2;
  position: relative;
}

.soco-emissions-overview-section--icon {
  text-align: center;
  margin-top: 24px;
}

.soco-emissions-overview-section--content {
  margin-top: 16px;
}

.soco-emissions-report--detail strong {
  font-weight: 700;
}

.soco-emissions-report--detail {
  padding: 24px 40px;
  color: #43712d;
  overflow: auto;
}

.soco-emissions-report-detail--separator {
  width: 100%;
  border-bottom: 1px solid #e4e4e3;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.soco-emissions-report-detail--section {
  display: flex;
  justify-content: space-between;
}

.soco-emissions-report-detail--disclaimers {
  font-size: 9px;
  margin-top: 24px;
}

.soco-emissions-report-detail-rates--section {
  flex-shrink: 0;
  width: 50%;
}

.soco-emissions-report-detail-rates--table td:first-child {
  text-align: center;
}

.soco-emissions-report-detail-rates--table tr:nth-child(odd) {
  background: #edf3cf;
}

.soco-emissions-report-detail-rates--table tr:nth-child(even) {
  background: #dce7a7;
}

.soco-emissions-report-detail-rates--table {
  margin-top: 16px;
}

.soco-emissions-report-detail-rates--table td {
  color: black;
  padding: 4px 16px;
}

.soco-emissions-report-detail-rates--table th {
  background: #508d33;
  color: white;
  padding: 4px 16px;
}

.soco-emissions-report-detail-rate--info {
  display: flex;
  align-items: center;
}

.soco-emissions-report-detail-rate--info li {
  margin-bottom: 12px;
}

.soco-emissions-report-detail-rate--info > ul {
  color: black;
}

.soco-emissions-report-overview--separator {
  position: relative;
  z-index: 0;
  border-top: 1px solid #508d33;
  top: -10px;
}